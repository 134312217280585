
import Nav from "./Project/Nav"
import Index from "./Project/Index"
import Footer from "./Project/Footer";
import About from "./Project/About";
import Contact from "./Project/Contact";
import Services from "./Project/Services";
import Laptop from "./Project/Laptop"
import Product from "./Project/Product";
import Interactive from "./Project/Interactive";
import "./App.css"
import {Route,Routes} from "react-router-dom"
import Commercial from "./Project/Commercial";
import Led from "./Project/Led";
import Desktop from "./Project/Desktop";
import Memory from "./Project/Memory";
import Ram from "./Project/Ram";
import Computer from "./Project/Computer";
import Tab from "./Project/Tab";
import Sata from "./Project/Sata";
import Usb from "./Project/Usb";
import It from "./Project/It";
import Dis from "./Project/Dis";
import Tech from "./Project/Tech";
import Cc from "./Project/Cc";
import Server from "./Project/Server";
import Network from "./Project/Network";
import Home from "./Project/Home";
import Consumer from "./Project/Consumer";
import Amc from "./Project/Amc";
import Sales from "./Project/Sales";



function App() {
  
  return (
  <>


  <Nav/>
  <Routes>
<Route path="/" element={<Index/>}/>
<Route path="/product" element={<Product/>}>
<Route path="memory" element={<Memory/>}/>
<Route path="ram" element={<Ram/>}/>
<Route path="laptop" element={<Laptop/>}/>
<Route path="interactive" element={<Interactive/>}/>
<Route path="commercial" element={<Commercial/>}/>
<Route path="led" element={<Led/>}/>
<Route path="desktop" element={<Desktop/>}/>
<Route path="computer" element={<Computer/>}/>
<Route path="tablet" element={<Tab/>}/>
<Route path="sata" element={<Sata/>}/>
<Route path="usb" element={<Usb/>}/>
</Route>
<Route path="/about" element={<About/>}/>
<Route path="/contact" element={<Contact/>}/>
<Route  path= "/services" element={<Services/>}>
<Route path="it" element={<It/>}/>
<Route path="dis" element={<Dis/>}/>
<Route path="tech" element={<Tech/>}/>
<Route path="cctv" element={<Cc/>}/>
<Route path="server" element={<Server/>}/>
<Route path="network" element={<Network/>}/>
<Route path="home" element={<Home/>}/>
<Route path="consumer" element={<Consumer/>}/>
<Route path="amc" element={<Amc/>}/>
<Route path="sales" element={<Sales/>}/>
</Route>
  </Routes>
  <Footer/>


  </>
  
  );
}

export default App;
